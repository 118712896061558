<template>
  <div class="OwnerDecorationGuidanceForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      @update="update"
    >
      <el-form-item label="标题" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]" prop="title">
        <v-input v-model="form.title"></v-input>
      </el-form-item>
      <el-form-item label="内容" prop="content" :rules="[{ required: true, message: '请输入内容', trigger: 'change' }]">
        <v-ueditor v-model="form.content"></v-ueditor>
      </el-form-item>
      <el-form-item v-if="!guideId && (userType === '100' || userType === '102')" label="发布项目" prop="communityIds" :rules="[{ required: true, validator: communityIdsVaild, trigger: 'change1' }]">
        <chosenListPanel :list.sync="communityIdsList" @select="toSelectCommunity"></chosenListPanel>
       </el-form-item>
      <el-form-item v-if="form.communityName" label="发布项目">
        {{form.communityName}}
       </el-form-item>
       <multi-select
        title="项目列表"
        :isShow.sync="isShow"
        :searchUrl="getCommunityListUrl"
        :headers="communityTableHeader"
        :searchParams="searchParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :backFill.sync="communityIdsList"
        :handleData="handleData">
          <template #searchSlot>
            <v-input label="项目名称" v-model="searchParams.communityName"></v-input>
            <v-select v-if="false" label="区域类型" v-model="searchParams.searchRegionType" :options="regionTypeOps"></v-select>
            <!--此处所属区域只需要选择租户-->
            <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
            <v-input label="所在省" v-model="searchParams.province"></v-input>
            <v-input label="所在城市" v-model="searchParams.city"></v-input>
            <v-input label="所在区" v-model="searchParams.area"></v-input>
            <!-- <v-select2 label="运营项目组" v-model="searchParams.operateRegionId" v-bind="operateRegionParams"></v-select2> -->
            <v-select label="项目阶段状态" v-model="searchParams.communityStage" :options="communityStageOps"></v-select>
            <v-select label="项目类型" v-model="searchParams.communityType" :options="communityTypeOps"></v-select>
            <v-select label="项目状态" v-model="searchParams.communityStatus" :options="statusOps"></v-select>
          </template>
      </multi-select>
      <div slot='footerSlot'>
        <v-button class="botBtn" text="预览" @click="isPreviewShow = true"></v-button>
        <v-button class="botBtn" text="保存" type="success" @click="submitGuide"></v-button>
      </div>
    </form-panel>
    <mobile-preview :isShow.sync="isPreviewShow" title="业主装修引导预览">
      <div class="content" slot="content">
        <div class="title">{{form.title}}</div>
        <div v-html="form.content"></div>
      </div>
    </mobile-preview>
  </div>
</template>
<script>
import { vUeditor } from 'components/control'
import { MultiSelect, ChosenListPanel, MobilePreview } from 'components/bussiness'
import {
  uploadGuideURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
} from './api'
import { setCommunityStatusOps, communityStatusMap, regionTypeOps } from './map'
import { tenantParams } from 'common/select2Params'
export default {
  components: {
    MultiSelect,
    ChosenListPanel,
    vUeditor,
    MobilePreview
  },
  data () {
    return {
      queryUrl: `${uploadGuideURL}/${this.$route.query.id}`,
      statusOps: setCommunityStatusOps(1),
      regionTypeOps,
      form: {
        title: '',
        content: '',
        communityId: ''
      },
      communityIdsList: [],
      tenantParams,
      guideId: this.$route.query.id,
      getCommunityListUrl: getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态'
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      isShow: false,
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: undefined
      },
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        }
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      communityStageOps: [],
      communityTypeOps: [],
      isPreviewShow: false
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  mounted () {
    this.getProjectTypes()
    this.getRoomTypes()
    this.guideId && this.$refs.form.getData({ id: this.guideId })
  },
  methods: {
    update (data) {
      this.form = {
        title: data.title,
        content: data.content,
        communityName: data.communityName
      }
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    toSelectCommunity () {
      this.isShow = true
    },
    communityIdsVaild (rule, value, callback) {
      if (this.ruleId) {
        callback()
      } else {
        if (this.communityIdsList.length > 0) {
          callback()
        } else {
          callback(new Error('请选择发布项目'))
        }
      }
    },
    submitGuide () {
      let isContinue = this.$refs.form.validate()
      if (!isContinue) {
        return
      }
      let data = this.form
      let params = {
        title: data.title,
        content: data.content,
        communityId: JSON.stringify(this.communityIdsList.map(item => item.id))
      }
      this.guideId && (params.id = this.guideId)
      let url = this.guideId ? `${uploadGuideURL}/info` : uploadGuideURL
      let method = this.guideId ? 'put' : 'post'
      this.$axios[method](url, params).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.botBtn {
  width: 168px;
  margin-right: 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0 0px;
  color: #404040;
  word-break: break-all;
}
</style>
