var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OwnerDecorationGuidanceForm-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { form: _vm.form, queryUrl: _vm.queryUrl },
          on: { update: _vm.update },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题",
                rules: [
                  { required: true, message: "请输入标题", trigger: "blur" },
                ],
                prop: "title",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "内容",
                prop: "content",
                rules: [
                  { required: true, message: "请输入内容", trigger: "change" },
                ],
              },
            },
            [
              _c("v-ueditor", {
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          !_vm.guideId && (_vm.userType === "100" || _vm.userType === "102")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发布项目",
                    prop: "communityIds",
                    rules: [
                      {
                        required: true,
                        validator: _vm.communityIdsVaild,
                        trigger: "change1",
                      },
                    ],
                  },
                },
                [
                  _c("chosenListPanel", {
                    attrs: { list: _vm.communityIdsList },
                    on: {
                      "update:list": function ($event) {
                        _vm.communityIdsList = $event
                      },
                      select: _vm.toSelectCommunity,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.communityName
            ? _c("el-form-item", { attrs: { label: "发布项目" } }, [
                _vm._v("\n      " + _vm._s(_vm.form.communityName) + "\n     "),
              ])
            : _vm._e(),
          _c("multi-select", {
            attrs: {
              title: "项目列表",
              isShow: _vm.isShow,
              searchUrl: _vm.getCommunityListUrl,
              headers: _vm.communityTableHeader,
              searchParams: _vm.searchParams,
              responseParams: _vm.responseParams,
              responseKey: _vm.responseKey,
              backFill: _vm.communityIdsList,
              handleData: _vm.handleData,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.isShow = $event
              },
              "update:is-show": function ($event) {
                _vm.isShow = $event
              },
              "update:backFill": function ($event) {
                _vm.communityIdsList = $event
              },
              "update:back-fill": function ($event) {
                _vm.communityIdsList = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "项目名称" },
                      model: {
                        value: _vm.searchParams.communityName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityName", $$v)
                        },
                        expression: "searchParams.communityName",
                      },
                    }),
                    false
                      ? _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.regionTypeOps,
                          },
                          model: {
                            value: _vm.searchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression: "searchParams.searchRegionType",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属公司" },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.tenantParams,
                        false
                      )
                    ),
                    _c("v-input", {
                      attrs: { label: "所在省" },
                      model: {
                        value: _vm.searchParams.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "province", $$v)
                        },
                        expression: "searchParams.province",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在城市" },
                      model: {
                        value: _vm.searchParams.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "city", $$v)
                        },
                        expression: "searchParams.city",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在区" },
                      model: {
                        value: _vm.searchParams.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "area", $$v)
                        },
                        expression: "searchParams.area",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目阶段状态",
                        options: _vm.communityStageOps,
                      },
                      model: {
                        value: _vm.searchParams.communityStage,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityStage", $$v)
                        },
                        expression: "searchParams.communityStage",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目类型",
                        options: _vm.communityTypeOps,
                      },
                      model: {
                        value: _vm.searchParams.communityType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityType", $$v)
                        },
                        expression: "searchParams.communityType",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "项目状态", options: _vm.statusOps },
                      model: {
                        value: _vm.searchParams.communityStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityStatus", $$v)
                        },
                        expression: "searchParams.communityStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                staticClass: "botBtn",
                attrs: { text: "预览" },
                on: {
                  click: function ($event) {
                    _vm.isPreviewShow = true
                  },
                },
              }),
              _c("v-button", {
                staticClass: "botBtn",
                attrs: { text: "保存", type: "success" },
                on: { click: _vm.submitGuide },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mobile-preview",
        {
          attrs: { isShow: _vm.isPreviewShow, title: "业主装修引导预览" },
          on: {
            "update:isShow": function ($event) {
              _vm.isPreviewShow = $event
            },
            "update:is-show": function ($event) {
              _vm.isPreviewShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.form.title)),
              ]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.form.content) } }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }