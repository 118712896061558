import {
  getAllRegionListURL,
  getCommunityListURL,
  getOrgListURL,
  getShopListURL,
  getStoreListURL,
  getRoomListURL,
  getTenantListURL
} from './api'

// 分子公司
const regionParams = {
  searchUrl: getTenantListURL,
  request: {
    text: 'name',
    value: 'id'
  }
}
// 所属公司
const tenantParams = {
  searchUrl: getTenantListURL,
  request: {
    text: 'name',
    value: 'id'
  }
}
// 所属区域
const allRegionParams = {
  searchUrl: getAllRegionListURL,
  request: {
    text: 'regionName',
    value: 'regionId'
  }
}
// 所属项目
const communityParams = {
  searchUrl: getCommunityListURL,
  request: {
    text: 'communityName',
    value: 'id'
  }
}

// 所属组织
const orgParams = {
  searchUrl: getOrgListURL,
  request: {
    text: 'orgName',
    value: 'id'
  },
  response: {
    text: 'orgName'
  }
}

// 所属商家
const shopParams = {
  searchUrl: getShopListURL,
  request: {
    text: 'busName',
    value: 'id'
  }
}

// 所属门店
const storeParams = {
  searchUrl: getStoreListURL,
  request: {
    text: 'storeName',
    value: 'id'
  }
}

// 所属项目（不含已关闭、已失效房号）

const normalCommunityParams = {
  searchUrl: getCommunityListURL,
  request: {
    text: 'communityName',
    value: 'id'
  },
  subjoin: {
    isNormal: 1
  }
}

// 房号查询
const roomParams = {
  searchUrl: getRoomListURL,
  request: {
    text: 'roomNum',
    value: 'houseId'
  }
}

export {
  regionParams,
  allRegionParams,
  communityParams,
  orgParams,
  shopParams,
  storeParams,
  normalCommunityParams,
  roomParams,
  tenantParams
}
