import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

const statusMap = generateMapByOpts(statusOps)

// 项目状态
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)
// 区域类型
const regionTypeOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '分子公司',
    value: 0
  }, {
    text: '园区公司分公司',
    value: 1
  }, {
    text: '管理项目组',
    value: 2
  }
]

const regionTypeMap = generateMapByOpts(regionTypeOps)

export {
  statusOps,
  statusMap,
  regionTypeOps,
  regionTypeMap,
  communityStatusMap,
  setCommunityStatusOps
}
