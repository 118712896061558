// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}decoration/guide/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}decoration/export/guide`
// 更新表单
const uploadGuideURL = `${API_CONFIG.butlerBaseURL}decoration/guide`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
export {
  getListURL,
  exportListURL,
  uploadGuideURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
}
